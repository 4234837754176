import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '..';
import {BASE_URL} from '../../../environments/commons/base-url';
import {BASE_URL_PROD} from '../../../environments/commons/base-url-prod';
import {environment} from "../../../environments/environment";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.userService.getCurrentTenant()) {
      if (req.url.includes(BASE_URL_PROD.REST)) {
        return next.handle(
          req.clone({
            headers: req.headers
              .set('x-company', this.userService.getCurrentTenant().toLowerCase())
              .set('x-user', this.userService.getUser().username)
          })
        );
      } else if (req.url.includes(BASE_URL.REST)) {
        return next.handle(
          req.clone({
            headers: req.headers
              .set('x-company', this.userService.getCurrentTenant().toLowerCase())
              .set('x-user', this.userService.getUser().username)
              .set('x-solution', environment.SOLUTION)
          })
        );
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
