import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {ValidationService} from '../../services/validation/validation.service';

@Component({
  selector: 'cube-validation-error',
  template: `<span class="error" *ngIf="control.invalid && (control.touched || control.dirty)">{{ errorMsg }}</span>`,
  styles: [
    `
      .error {
        color: #ff2c00;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-size: 11px;
        line-height: 1.125;
      }
    `,
  ],
})
export class ValidationErrorComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() field: string;

  constructor(private validationService: ValidationService) {}

  ngOnInit(): void {}

  get errorMsg(): string {
    const errors = this.control.errors;

    for (const propertyName in errors) {
      if (errors.hasOwnProperty(propertyName)) {
        return this.validationService.getMessage(propertyName, errors[propertyName], this.field);
      }
    }
    return null;
  }
}
