import {KeycloakOptions, KeycloakService} from 'keycloak-angular';
import { User } from './models/user.model';
import {CubeService, UserService} from './services';
import {PROFILE_NOT_VALID, TENANT_CONFIG} from '../environments/config';
import {UserApiService} from './services/api/user/user.api.service';
import {STORAGE_KEYS} from './services/storage/storage.service';
import {environment} from '../environments/environment';
import {CubeApiService} from './services/api/cube/cube.api.service';

// https://medium.com/@gmurop/managing-dependencies-among-app-initializers-in-angular-652be4afce6f
// https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/

export function authInitializer(
  keycloakService: KeycloakService,
  userService: UserService,
  userApiService: UserApiService,
  cubeService: CubeService,
  cubeApiService: CubeApiService): () => Promise<any> {
  try {
    return (): Promise<any> =>
      initKC(
        keycloakService,
        userService,
        userApiService,
        cubeService,
        cubeApiService).catch(reason => {
        if (reason === PROFILE_NOT_VALID) {
          window.location.href = '//cube.rina.org';
        }
      });
  } catch (e) {
    console.error(e);
  }
}

function initKC(
  keycloakService: KeycloakService,
  userService: UserService,
  userApiService: UserApiService,
  cubeService: CubeService,
  cubeApiService: CubeApiService): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const kcOption: KeycloakOptions = {
        config: environment.keycloak,
        loadUserProfileAtStartUp: false,
        initOptions: {
          onLoad: 'check-sso',
          // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
          checkLoginIframe: false
        },
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: environment.auth.bearerExcludedUrls,
      };

      const authentication = await keycloakService.init(kcOption);
      if (authentication) {
        const [kcUser, token, instance] = await Promise.all([
          keycloakService.loadUserProfile(),
          keycloakService.getToken(),
          keycloakService.getKeycloakInstance()
        ]);

        if (!kcUser) {
          throw PROFILE_NOT_VALID;
        }

        await userService.configureCurrentTenant(kcUser.username).toPromise();

        const user = kcUser as User;
        try {
          const userProfile = await userApiService.getProfile(kcUser.username).toPromise();
          user.rolesAndModules = [];
          userProfile.roles?.forEach(role => user.rolesAndModules.push({ name: role.name, roleModules: [] }));
          user.userGroups = userProfile.userGroups.map(e => e.name).sort();
          user.assets = userProfile.assets;
          user.assetGroups = userProfile.assetGroups;
        } catch (e) {
          throw PROFILE_NOT_VALID;
        }
        user.token = token;
        user.id = instance.idTokenParsed.sub;
        userService.setUser(user);
        userService.setActiveRole(
          localStorage.getItem(STORAGE_KEYS.activeRole) ||
          user.rolesAndModules.find(r => r.name === TENANT_CONFIG.settings.admin_tool?.tags.supervisor)?.name ||
          user.rolesAndModules[0]?.name
        );
        // wait: compose features and scopes of the active role.
        await userService.composeRoleFeatures(userService.getActiveRole()).toPromise();
        cubeService.configureLocalStorageVariables(token, instance);
        return resolve(true);
      } else {
        keycloakService
          .login({ idpHint: TENANT_CONFIG.settings.company?.identityProvider })
          .catch(e => console.error(e));
        return reject(false);
      }
    } catch (error) {
      return reject(error);
    }
  });
}

