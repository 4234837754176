import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export enum Errors {
  required = 'required',
  minLength = 'minlength',
  maxLength = 'maxlength',
  invalidName = 'invalidname',
  notInTheFuture = 'notinthefuture',
  invalidTotal = 'invalidtotal',
  alreadyExists = 'alreadyexists',
  alreadyAssigned = 'alreadyassigned',
  invalidNumber = 'invalidnumber',
  positiveNumber = 'positivenumber',
  invalidDecimalPlaces = 'decimalplaces',
  notANumber = 'notanumber',
  min = 'min',
  max = 'max',
  equalTo0 = 'equalto0',
  coeffNull = 'coeffnull',
  notAConst = 'notaconst'
}

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  public getMessage(errorName: string, errorValue: any, fieldName: string): string {
    console.log(errorName, errorValue, fieldName)
    const minmax =
      fieldName === 't' || fieldName === 'threshold' ? `, ${fieldName} Min must be less than ${fieldName} Max` : ``;
    const errorMessages = {
      [Errors.required]: `Field is required`,
      [Errors.invalidName]: `Should be a valid name`,
      [Errors.alreadyExists]: `Already exists`,
      [Errors.alreadyAssigned]: `ID is already assigned. Choose different one.`,
      [Errors.notInTheFuture]: `Date in the Future`,
      [Errors.invalidTotal]: `Total is not valid`,
      [Errors.maxLength]: `Maximum ${errorValue.requiredLength} characters allowed`,
      [Errors.invalidNumber]: `Only decimal number allowed`,
      [Errors.positiveNumber]: `Only positive number allowed`,
      [Errors.invalidDecimalPlaces]: `Maximum ${errorValue.required} decimals allowed`,
      [Errors.notANumber]: `Unable to calculate`,
      [Errors.equalTo0]: `The denominator is equal to 0`,
      [Errors.coeffNull]: `At least one x coefficient must be nonzero`,
      [Errors.notAConst]: `Function cannot be a constant`,
    };
    return errorMessages[errorName];
  }

  public getDecimalRegex(decimals: number): RegExp {
    return new RegExp(`^-?\\d+(\\.\\d{1,${decimals}})?$`);
  }

  public regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value == null) {
        return null;
      }
      return regex.test(control.value) ? null : error;
    };
  }
}
