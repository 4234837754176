import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {API} from '../api.const';

@Injectable({
  providedIn: 'root'
})
export class CubeApiService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  public getPlatformInfo(): Observable<any> {
    return this.http.get<any>(API.ZPAGES.varz()).pipe(
      tap(_ => this.logger.debug('GET get platform Info')),
      catchError(this.handleError<any>('getInfo()'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
