import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SystemNotificationOptionsData } from './models/system-notification.class';

@Component({
  selector: 'cube-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: SystemNotificationOptionsData) { }
}
