<div class="toolbar-user-container">
  <button *ngIf="!user" mat-button (click)="doLogin()" class="toolbar-user-btn" [ngStyle.xs]="{ 'min-width': '70px' }">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" src="./assets/images/avatars/noavatar.png"/>
      <span class="name" fxHide fxShow.gt-xs>Log In</span>
    </span>
  </button>
  <button
    *ngIf="user"
    mat-button
    (click)="isOpen = !isOpen"
    class="toolbar-user-btn"
    [class.open]="isOpen"
    [ngStyle.xs]="{ 'min-width': '70px' }"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <img class="avatar" src="./assets/profile.jpg"/>
      <div class="name" fxLayoutAlign="center center" fxLayout="column" fxHide fxShow.gt-xs>
        {{ (user?.firstName || user.username)}} {{ user?.lastName }}
        <cite fxLayoutAlign="center center" fxHide fxShow.gt-xs class="active-role">{{ activeTenant | uppercase }} - {{ activeRole }}</cite>
      </div>
      <mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <div *ngIf="user" class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <mat-nav-list>
        <mat-list-item>
          <a matLine (click)="goProfile()">My Profile</a>
          <button mat-icon-button>
            <mat-icon>account_circle</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="enableTenantChange">
          <a matLine (click)="changeTenant()">Tenant List</a>
          <button mat-icon-button>
            <mat-icon>language</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="user?.rolesAndModules.length > 1">
          <a matLine (click)="changeProfile()">Roles</a>
          <button mat-icon-button>
            <mat-icon>compare_arrows</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="enableAdminTool">
          <a matLine (click)="goAdminTool()">Admin Tool</a>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="enableMailingList">
          <a matLine routerLink="/cube/mailing-list" routerLinkActive="active">Setup Mailing List</a>
          <button mat-icon-button>
            <mat-icon>email</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="enableAppMobile">
          <a matLine routerLink="/cube/qr-code" routerLinkActive="active">Mobile Pairing</a>
          <button mat-icon-button>
            <mat-icon>phonelink_ring</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine (click)="openSupportPage()">Support</a>
          <button mat-icon-button>
            <mat-icon>headset_mic</mat-icon>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <a (click)="changeTheme()" matLine>Change theme</a>
          <button mat-icon-button>
            <mat-icon>invert_colors</mat-icon>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <a matLine (click)="doLogout()">Logout</a>
          <button mat-icon-button>
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
</div>
