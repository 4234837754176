<mat-toolbar class="mat-elevation-z4">
  <button mat-icon-button (click)="sidenav.toggle();drawer.toggle();" *ngIf="matDrawerShow">
    <i class="material-icons app-toolbar-menu">menu </i>
  </button>
  <button mat-icon-button (click)="sidenav.toggle();" *ngIf="!matDrawerShow">
    <i class="material-icons app-toolbar-menu">menu </i>
  </button>

  <span class="spacer"></span>

<!--  <button mat-icon-button (click)="searchOpen = !searchOpen" fxHide="true" [fxHide.gt-xs]="false">-->
<!--    <i class="material-icons">search</i>-->
<!--  </button>-->
  <cube-search-bar [open]="searchOpen" (search)="onSearch($event)"></cube-search-bar>

  <cube-fullscreen></cube-fullscreen>

  <cube-user-menu [user]="currentUser" (onDepartmentClick)="this.onDepartmentClick.emit($event)"></cube-user-menu>
</mat-toolbar>
