import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cube-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  public searchBox: boolean = false;
  public isLoading: boolean = false;
  public searchForm: FormGroup;
  public results: any[] = [];

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() set open(o: boolean) {
    this.searchBox = o;
    if (o) {
      setTimeout(
        function () {
          this.searchInput.nativeElement.focus();
        }.bind(this),
        environment.render_delay
      );
    }
  }

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      search: null,
    });

    this.searchForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(300),
        tap(() => (this.isLoading = true))
        // switchMap(value => this.searchService.searchShip(value).pipe(finalize(() => (this.isLoading = false))) )
      )
      .subscribe((results: any) => {
        const filterValue = this.searchForm.get('search').value.toString().toLowerCase();
        this.results = results.ships.filter((r: any) => {
          return (
            r.name.toLowerCase().includes(filterValue) === true || r.imo.toLowerCase().includes(filterValue) === true
          );
        });
      });
  }

  displayFn(res: any) {
    if (res) {
      return res.name;
    }
  }

  onOptionSelected(e: any) {
    if (this.searchBox) {
      this.search.emit(e.option.value);
    }
  }
}
