import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { LoggerModule } from 'ngx-logger';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';

import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './sidemenu-item/sidemenu-item.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { PipesModule } from '../pipes/pipes.module';

import { environment } from '../../environments/environment';
import { ActivableCardComponent } from './activable-card/activable-card.component';
import { ActivableCardGroupDirective } from './activable-card/activable-card-group';
import { DirectivesModule } from '../directives/directives.module';
import {GenericMsListComponent} from './generic-ms-list/generic-ms-list.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {ValidationErrorComponent} from './validation-error/validation-error.component';
import {IndeterminateBarModule, MessageService} from '@cube/lib-common';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    ActivableCardComponent,
    ActivableCardGroupDirective,
    GenericMsListComponent,
    ValidationErrorComponent
  ],
    imports: [
        PerfectScrollbarModule,
        CommonModule,
        MomentModule,
        RouterModule,
        PipesModule,
        MaterialModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        DirectivesModule,
        AgGridModule,
        LoggerModule.forRoot(environment.logger),
        IndeterminateBarModule,
    ],
  exports: [
    ActivableCardGroupDirective,
    ActivableCardComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    GenericMsListComponent,
    ValidationErrorComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    MessageService
  ],
})
export class CoreModule {}
