import { Type } from "@angular/core";
import { ActionReducerMap, RuntimeChecks } from "@ngrx/store";
import { StoreDevtoolsConfig } from "@ngrx/store-devtools";
import { SystemEffects } from "./effects/system.effects";
import { systemReducers } from "./reducers/system.reducers";
import { AppState } from "./states/app.state";

export const reducers: ActionReducerMap<AppState> = {
	system: systemReducers
};

export const effects: Array<Type<unknown>> = [SystemEffects];

export const runtimeChecks: RuntimeChecks = {
	strictActionImmutability: true,
	strictActionSerializability: true,
	strictActionWithinNgZone: true,
	strictStateImmutability: true,
	strictStateSerializability: true,
	strictActionTypeUniqueness: true
};

export const devtoolsConfig: StoreDevtoolsConfig = {
  maxAge: 25,
  logOnly: false
};
