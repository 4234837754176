import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '..';
import { environment } from '../../../environments/environment';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('cube.rina.org')) {
      if (this.userService.getCurrentTenant()) {
        // console.log(this.userService.getCurrentTenant());
        return next.handle(
          req.clone({
            headers: req.headers
              .set('x-company', this.userService.getCurrentTenant().toLowerCase())
                .set('x-solution', environment.SOLUTION)
          })
        );
      } else {
        return next.handle(
          req.clone({
            headers: req.headers.set('x-solution', environment.SOLUTION)
          })
        );
      }
    } else {
      return next.handle(req);
    }
  }
}
