import { fromEvent as observableFromEvent, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { UserService } from '../user/user.service';
import { TimeWindow } from '../../models/time-window.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {STORAGE_KEYS, StorageService} from '../storage/storage.service';
import {VERSION} from '../../../environments/version';
import {AUTH_CONFIG, TENANT_CONFIG} from '../../../environments/config';
import {MODULES} from '../../../environments/module.config';

export const enum MessageType {
  Info,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class CubeService {
  public changeCubeMenu$: Subject<any> = new Subject();

  private _refreshing_time = 5000; // in milliseconds
  private _tokenSbj = new Subject<string>();
  private _timeWindowSbj = new Subject<TimeWindow>();
  private _timeWindow: TimeWindow = new TimeWindow();
  private now: moment.Moment;
  private tsSbj = new Subject<moment.Moment>();
  public onlineStatus: Observable<Event>;
  public offlineStatus: Observable<Event>;
  public sidenavSbj: Subject<any> = new Subject();
  public colorSeed = Date.now();
  private version = VERSION;
  public ws: Subject<any>;

  constructor(
    private userService: UserService,
    private keycloakService: KeycloakService,
    private logger: NGXLogger,
    private storage: StorageService,
    private snackBar: MatSnackBar
  ) {
    if (environment.refreshing_time) {
      const rt = +environment.refreshing_time.slice(0, -1);
      switch (environment.refreshing_time.charAt(environment.refreshing_time.length - 1)) {
        case 'm':
          this._refreshing_time = rt * 60000;
          break;
        case 's':
        default:
          this._refreshing_time = rt * 1000;
          break;
      }
      this.onlineStatus = observableFromEvent(window, 'online');
      this.offlineStatus = observableFromEvent(window, 'offline');
    }

    interval(this._refreshing_time).subscribe(
      (x) => this.updateTime(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    interval(this._refreshing_time).subscribe(
      (x) => this.setTimeSlider(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    interval(environment.auth.refreshing_time).subscribe(
      (x) => this.refreshSession(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );

    this.setTimeSlider();
    this.updateTime();
  }

  public getVersion(): string {
    return this.version.version + '-' + this.version.hash;
  }

  refreshSession(): void {
    this.keycloakService
      .updateToken(AUTH_CONFIG.token_lifespan)
      .then((updated: boolean) => {
        if (updated) {
          this.keycloakService.getToken().then((t) => {
            localStorage.setItem(STORAGE_KEYS.token, t);
            this.userService.updateToken(t);
            this._tokenSbj.next(t);
          });
        }
      })
      .catch((e) => {
        // this.logger.error(e);
        window.location.reload();
      });
  }

  setTimeSlider(from?: moment.Moment, to?: moment.Moment): void {
    this._timeWindow.now = moment().utc();
    if (!environment.production) {
      this._timeWindow.now.subtract(1, 'years');
    }
    this._timeWindow.from = from || moment(this._timeWindow.now).subtract(7, 'days');
    this._timeWindow.to = to || moment(this._timeWindow.now);
    this._timeWindowSbj.next(this._timeWindow);
  }

  getTimeWindow(): TimeWindow {
    return this._timeWindow;
  }

  getTSObservable(): Observable<moment.Moment> {
    return this.tsSbj.asObservable();
  }

  timeWindow(): Observable<TimeWindow> {
    return this._timeWindowSbj.asObservable();
  }

  public token(): Observable<string> {
    return this._tokenSbj.asObservable();
  }

  getTS(): moment.Moment {
    return this.now;
  }

  private updateTime(): void {
    this.now = moment().utc();
    this.tsSbj.next(this.now);
  }
  public getUserMenu(): any {
    const myMenu = [];

    Object.keys(MODULES).forEach((page: string) => {
      const module = { ...MODULES[page] } as any;
      if (module.menu && this.userService.hasTenantModules(module.roles)) {
        if (module.menu.sub) {
          module.menu.sub = module.menu.sub.filter(subMenuItem =>
            MODULES[subMenuItem.key] ? this.userService.hasTenantModules(MODULES[subMenuItem.key].roles) : false
          );
        }
        myMenu.push(module.menu);
      }
    });

    TENANT_CONFIG.settings.externalPages?.forEach((page: any) => {
      if (this.userService.hasTenantModules(page.roles)) {
        myMenu.push({
          name: page.name,
          mat_icon: page.icon,
          open: false,
          external: true,
          link: page.link
        });
      }
    });
    // console.log(myMenu);
    return myMenu;
  }
  public cleanCache(): void {
    this.storage.deleteFromStorage(STORAGE_KEYS.activeRole);
    this.storage.deleteFromStorage(STORAGE_KEYS.selectedShips);
    this.storage.deleteFromStorage(STORAGE_KEYS.selectedShip);
    this.storage.deleteFromStorage(STORAGE_KEYS.activeRole);
    this.storage.deleteFromStorage(STORAGE_KEYS.analyticsDashboard);
    this.storage.deleteFromStorage(STORAGE_KEYS.excludedMap);
    this.storage.deleteFromStorage(STORAGE_KEYS.kpiActivation);
    this.storage.deleteFromStorage(STORAGE_KEYS.analyticsMode);
    this.storage.deleteFromStorage(STORAGE_KEYS.syncAssetPicker);
    this.storage.deleteFromStorage(STORAGE_KEYS.userFilters);
  }

  public configureLocalStorageVariables(token, instance): void {
    if (
      (localStorage.getItem(STORAGE_KEYS.version) &&
        localStorage.getItem(STORAGE_KEYS.version) !== this.getVersion()) ||
      (localStorage.getItem(STORAGE_KEYS.sub) && localStorage.getItem(STORAGE_KEYS.sub) !== instance.idTokenParsed.sub)
    ) {
      localStorage.clear();
      indexedDB.deleteDatabase('ngStorage');
      localStorage.setItem(STORAGE_KEYS.currentTenant, this.userService.userTenantsList[0]);
    }

    localStorage.setItem(STORAGE_KEYS.token, token);
    localStorage.setItem(STORAGE_KEYS.version, this.getVersion());
    localStorage.setItem(STORAGE_KEYS.sub, instance.idTokenParsed.sub);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error.message);

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  showMessage(type: MessageType, msg: string): void {
    const snackBarConfig = new MatSnackBarConfig();
    switch (type) {
      case MessageType.Info:
        snackBarConfig.duration = environment.snackbar_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'infoMessage'];
        break;
      case MessageType.Error:
        snackBarConfig.duration = environment.snackbar_error_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'errorMessage'];
        break;
    }
    this.snackBar.open(msg, 'close', snackBarConfig);
  }
}
