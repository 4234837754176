import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '@cube/lib-common';
import {User} from '../../models/user.model';
import {CubeService, UserService} from '../../services';
import {STORAGE_KEYS, StorageService} from '../../services/storage/storage.service';
import {ThemeService} from '../../services/theme/theme.service';
import {TENANT_CONFIG} from '../../../environments/config';
import {environment} from '../../../environments/environment';
import {Solutions} from '../../models/environment-configuration';

@Component({
  selector: 'cube-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isOpen = false;
  activeRole: string;
  activeTenant: string;
  enableAdminTool: boolean;
  enableMailingList: boolean;
  enableAppMobile: boolean;
  enableTenantChange: boolean;

  @Output() onDepartmentClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: User = null;
  private theme: boolean;

  constructor(
    private elementRef: ElementRef,
    private userService: UserService,
    private storage: StorageService,
    public dialog: MatDialog,
    private cubeService: CubeService,
    private themeService: ThemeService
  ) {}

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  ngOnInit(): void  {
    this.enableAdminTool = this.user.rolesAndModules.map(r => r.name).includes(TENANT_CONFIG.settings.superUserRoleId);
    this.themeService.isDarkTheme.subscribe(theme => {
      this.theme = theme;
    });
    this.activeRole = this.userService.getActiveRole();
    this.activeTenant = this.userService.getCurrentTenant();
    this.enableTenantChange = environment.SOLUTION === Solutions.ALLEANZA && this.userService.userTenantsList.length > 1;
  }

  doLogout(): void {
    this.userService.doLogout();
  }

  doLogin(): void {
    this.userService.doLogin();
  }

  goProfile(): void {
    this.userService.goToProfile();
  }

  goAdminTool(): void {
    window.open(environment.ADMIN_TOOL_WEB, '_blank');
  }

  changeTheme(): void {
    this.themeService.setDarkTheme(!this.theme);
  }

  changeProfile(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Roles selection',
        confirmLabel: 'Ok',
        cancelLabel: 'Cancel',
        inputs: [
          {
            type: 'select',
            label: 'Select department',
            required: true,
            options: this.userService.getAllRoles(),
            value: this.userService.getActiveRole()
          }
        ]
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        this.onDepartmentClick.emit();
        localStorage.setItem(STORAGE_KEYS.activeRole, result.data.inputs[0].value);
        window.location.href = 'dias/homepage';

      }
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result?.confirm) {
    //     const selected = result.data.inputs[0].value;
    //     this.onDepartmentClick.emit(selected);
    //     this.activeRole = selected;
    //   }
    // });
  }

  changeTenant(): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Tenant selection',
        confirmLabel: 'Ok',
        cancelLabel: 'Cancel',
        inputs: [
          {
            type: 'select',
            label: 'Select tenant',
            required: true,
            options: this.userService.userTenantsList,
            value: this.userService.getCurrentTenant(),
            fieldDisplayed: ''
          }
        ]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        localStorage.setItem(STORAGE_KEYS.currentTenant, result.data.inputs[0].value);
        this.cubeService.cleanCache();
        window.location.href = 'dias/homepage';
      }
    });
  }

  public openSupportPage(): void {
    window.open('https://support.cube.rina.org', '_blank');
  }
}
