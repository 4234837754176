import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAuthGuard } from '../services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('../pages/error/error.module').then((m) => m.ErrorModule) },
  {
    path: 'dias',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('../cube/cube.module').then((m) => m.CubeModule),
  },
  { path: '**', redirectTo: 'dias/homepage' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AppAuthGuard],
})
export class LazyLoadModule {}
