import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VERSION } from '../../../environments/version';
import {Subject} from 'rxjs';
import {CubeService} from '../../services';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'cube-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  version = VERSION;
  protected _destroy$: Subject<any> = new Subject<any>();

  public menus: any;
  @Input() iconOnly = false;

  constructor(private cubeService: CubeService) {}

  ngOnInit(): void {
    console.log('V:' + this.version.version + '-' + this.version.hash);
    this.menus = this.cubeService.getUserMenu();
    this.cubeService.changeCubeMenu$.pipe(takeUntil(this._destroy$)).subscribe(s => {
      this.menus = this.cubeService.getUserMenu();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();  }
}
