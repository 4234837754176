import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AllCommunityModules, ColDef, GridOptions} from '@ag-grid-community/all-modules';
import {HttpClient} from '@angular/common/http';
import {CubeService, MessageType} from '../../services';
import {TruncateUrlPipe} from '../../pipes/truncate-url/truncate-url.pipe';
import {ReportFileService} from '../../services/api/report-file/report-file.service';
import {ResponseMessage} from '../../models/response.model';
import {TranslateService} from "@ngx-translate/core";
import {Status} from "../../models/audit.model";

@Component({
  selector: 'cube-generic-ms-list',
  templateUrl: './generic-ms-list.component.html',
  styleUrls: ['./generic-ms-list.component.scss']
})
export class GenericMsListComponent implements OnInit, OnChanges {
  @Input() set displayedRows(ms: any[]) {
    if (ms) {
      this.rowData = ms;
    }
  }

  @Input() set displayedColumns(cols: any[]) {
    if (cols) {
      this.columns = cols;
    }
  }
  @Input() rowHeight;
  @Output() rowDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToBeEdited: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToBeManaged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowInfo: EventEmitter<any> = new EventEmitter<any>();

  public showLoader = false;
  public columns: ColDef[] = [];
  public rowData: any[] = [];
  public urlsKey = ['pdf_url', 'pdf_notes_url', 'manual_report', 'audit_report', 'audit_attachment_url', 'audit_final_report_url', 'decarbon_pdf_url'];
  public modules = AllCommunityModules;
  public testGrid: GridOptions = {
    tooltipShowDelay: 0,
    applyColumnDefOrder: true,
    enableBrowserTooltips: true,
    getRowStyle: params => {
      if (params?.data?.audit_status === Status.revision) {
        console.log(params?.data?.audit_status)
        return { background: 'rgba(255, 76, 38, 0.3)' };
      }
    }
  };
  public defaultColDef = {
    resizable: true,
    wrapText: true,     // <-- HERE
    headerValueGetter: (data) => this.translateHeader(data),
  };
  private gridColumnApi: any;
  private gridApi: any;
  constructor(private readonly httpClient: HttpClient,
              private reportService: ReportFileService,
              private cubeService: CubeService,
              private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.columns.length && this.rowData.length && this.gridApi) {
      this.gridApi.setColumnDefs(this.columns);
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSize(): void {
    const allColumnIds = [];
    if (this.gridColumnApi && this.gridColumnApi.getAllColumns()) {
      this.gridColumnApi.getAllColumns().forEach(column => {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }

  ngOnInit(): void {
  }

  public onGridReady(event): void {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
  }

  onCellClicked(event): void {
    console.log(event);
    if (event?.data && event?.colDef?.field === 'edit' && event?.data?.editable) {
      this.rowToBeEdited.emit(event.data);
    }
    if (event?.data && event?.colDef?.field === 'edit' && !event?.data?.editable) {
      this.rowDetails.emit(event.data);
    }
    if (event?.data && event?.colDef?.field === 'info') {
      this.rowDetails.emit(event.data);
    }
    if (event?.data && event?.colDef?.field === 'manage') {
      this.rowToBeManaged.emit(event.data);
    }
    if (event?.data?.audit_revision_notes && event?.colDef?.field === 'audit_revision_notes') {
      this.rowInfo.emit(event.data);
    }
    if (this.urlsKey.find(url => event?.colDef?.field === url) && event?.data && event?.value) {
      this.showLoader = true;
      this.reportService.getOfflineFiles('file', null, event?.value).subscribe((resp) => {
        if (resp?.message === ResponseMessage.Success) {
          this.downloadFile(resp.data, event?.value);
        }
      });
    }
    if (event?.data && event?.colDef?.field === 'download-zip') {
      this.showLoader = true;
      this.reportService.getOfflineFiles('invoice', event?.data?.id, null).subscribe((resp) => {
        if (resp?.message === ResponseMessage.Success) {
          this.downloadFile(resp?.data, `attachments-${event?.data?.id}.zip`);
        }
      });
    }
  }

  downloadFile(downloadUrl: string, fileNameUrl: string): void {
    const truncateURL = new TruncateUrlPipe();
    const fileName = truncateURL.transform(fileNameUrl);

    this.reportService.downloadFile(downloadUrl).subscribe((response) => {
      if (response?.body) {
        this.reportService.createObjectUrl(response.body, fileName);
      } else {
        this.cubeService.showMessage(MessageType.Error, 'Oops! Something went wrong.');
      }
      this.showLoader = false;
    });
  }

  translateHeader(data): string {
    return this.translateService.instant(data.colDef.headerName);
  }

}
