import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';
import { UserService } from '../../services';

@Component({
  selector: 'cube-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output() onDepartmentClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;

  currentUser?: User = null;

  searchOpen = false;
  isSupportToolEnabled = true;
  isSettingPanelEnabled = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.isLoggedIn() ? (this.currentUser = this.userService.getUser()) : null;
  }

  ngOnDestroy(): void {}

  onAction(action: any): void {
    // console.log(action);
  }

  onSearch(search: any): void {}

  openSupportPage(): void {
    window.open(environment.supportUrl, '_blank');
  }
}
