import { KeycloakConfig } from 'keycloak-js';
import { NgxLoggerLevel } from 'ngx-logger';

const keycloakConfig: KeycloakConfig = {
  url: 'https://login.cube.rina.org/auth',
  realm: 'cube',
  clientId: 'arvedi',
};

const companyConfig: any = {
  logo: './assets/arvedi/arvedi-logo-dark.png',
  logoBg: 'bg12294b',
  name: 'DIAS Arvedi',
  role: '*',
};

const grantedMailAddresses: string[] = [];

export const globalEnvironment = {
  name: 'DIAS Arvedi',
  production: false,
  keycloak: keycloakConfig,
  company: companyConfig,
  routes: [],
  auth: {
    refreshing_time: 5000,
    token_lifespan: 60,
    bearerExcludedUrls: ['https://api.powerbi.com'],
    grantedMailAddresses,
  },
  api: {
    seismicEvents: 'seismic/events',
    geojson: 'seismic/geojson',
  },
  logger: {
    serverLoggingUrl: 'http://localhost:3000/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  },
  powerbi: {
    groupId: '1592c6ff-e53f-4888-9b1b-64c5ce8a6ac0',
    reportId: '0a0f5b21-1c6f-41ac-bf91-e60ce325ffa5',
  },
  render_delay: 400,
  refreshing_time: '10s',
  warn_timeout: 5000,
  error_timeout: 10000,
  snackbar_duration: 2000,
  snackbar_error_duration: 10000,
  date_format: 'MM-dd-yyyy HH:mm',
  first_available_date: '2021-01-01',
  supportUrl: 'https://support.cube.rina.org'
};
