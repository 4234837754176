export interface ResponseModel {
  message: ResponseMessage;
  data: any;
  error: string;
}

export enum ResponseMessage {
  Success = 'success',
  Fail = 'fail'
}
