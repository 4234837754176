import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';
import { PROFILE_NOT_VALID } from '../../../environments/config';
import { UserApiService } from '../api/user/user.api.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { STORAGE_KEYS } from '../storage/storage.service';
import { intersection } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  onDepartmentChange: Subject<string> = new Subject();
  userTenantsList: string[] = [];
  private _role: string;
  private _user: User = null;
  private logoutRedirect: string = window.location.origin;
  private profileRedirect =
    environment.keycloak.url +
    '/realms/' +
    environment.keycloak.realm +
    '/account/';

  constructor(
    private userApiService: UserApiService,
    private keycloakService: KeycloakService
  ) {}

  isLoggedIn(): boolean {
    return this._user != null;
  }

  getUser(): User {
    return this._user;
  }
  getActiveRole = () => this._role;
  isAdmin = (): boolean => this._role === 'administrator';

  getActiveRoleScopes = () =>
    this._user?.rolesAndModules.find((r) => r.name === this._role).scopes

  getAllRoles = () => this._user?.rolesAndModules.map((r) => r.name);

  setUser(user: User): void {
    this._user = user;
  }

  updateToken(token: string): void {
    this._user.token = token;
  }

  doLogout(redirect?: string): void {
    this._user = null;
    this.keycloakService.logout(redirect || this.logoutRedirect);
  }

  doLogin(): void {
    this.keycloakService.login();
  }

  goToProfile(): void {
    window.location.href = this.profileRedirect;
  }

  getCurrentTenant(): any {
    return localStorage.getItem(STORAGE_KEYS.currentTenant);
  }

  hasRole(roles: string | string[]): boolean {
    roles = [].concat(roles);
    return (
      roles.includes('*') ||
      this._user.rolesAndModules
        .map((m) => m.name)
        .some((r) => roles.includes(r))
    );
  }

  setActiveRole(role): void {
    localStorage.setItem(STORAGE_KEYS.activeRole, role);
    this._role = role;
    this.onDepartmentChange.next(role);
  }

  configureCurrentTenant(username: string): Observable<any> {
    return this.userApiService.getAllTenants(username, environment.SOLUTION).pipe(
      tap((tenants) => {
        if (tenants?.length) {
          this.userTenantsList = tenants.filter((t) => t !== 'root');
          if (!this.getCurrentTenant()) {
            localStorage.setItem(
              STORAGE_KEYS.currentTenant,
              this.userTenantsList[0]
            );
          }
        } else {
          throw PROFILE_NOT_VALID;
        }
      })
    );
  }

  hasTenantModules(featureName: string | string[], role: string = this._role): boolean {
    const features = [].concat(featureName);
    let has = false;
    const roleModulesFeatures = role
      ? [].concat(this._user.rolesAndModules.find((r) => r.name === role))
      : this._user.rolesAndModules;
    roleModulesFeatures.forEach((modulesList) => {
      modulesList.roleModules.forEach(module => {
        if (!!intersection(features, module.map((f) => f.name)).length) {
          has = true;
        }
      });
    });
    return has;
  }

  composeRoleFeatures(role: string): Observable<any> {
    return forkJoin([this.userApiService.getRoleFeatures(role)]).pipe(
      map((features) => {
        if (this._user) {
          this._user.rolesAndModules.find((r) => r.name === role).roleModules =
            features;
        }
        return this._user;
      })
    );
  }
}
