import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { authInitializer } from './app.initializer';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { ThemeService } from './services/theme/theme.service';
import { CubeService, UserService } from './services';
import { UserApiService } from './services/api/user/user.api.service';
import { CubeApiService } from './services/api/cube/cube.api.service';
import { TenantInterceptor } from './services/interceptors/tenant.interceptor';
import { HeadersInterceptor } from './services/interceptors/headers.interceptor';

import * as store from './store';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SpinnerModule } from './components/core/spinner/spinner.module';

// Load translations from "/assets/i18n/[lang].json"
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    LazyLoadModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(store.reducers),
    EffectsModule.forRoot(store.effects),
    StoreDevtoolsModule.instrument(store.devtoolsConfig),
    MatSnackBarModule,
    SpinnerModule
  ],
  exports: [TranslateModule],
  providers: [
    ThemeService,
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      multi: true,
      deps: [
        KeycloakService,
        UserService,
        UserApiService,
        CubeService,
        CubeApiService,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
