import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateUrl'
})
export class TruncateUrlPipe implements PipeTransform {
  /**
   * @param value string, return last substring
   */
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const subStrings = value.split('/');
    const lastSubStrings =  subStrings[subStrings.length - 1].split('?');
    return lastSubStrings[0];
  }
}
