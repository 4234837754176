<cube-indeterminate-bar *ngIf="showLoader"></cube-indeterminate-bar>
<div class="cube-scrollbar margin-table">
  <ag-grid-angular
    style="height: 80vh;"
    [rowHeight]="rowHeight"
    class="ag-theme-alpine-dark"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
    [gridOptions]="testGrid"
    [modules]="modules"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [suppressCellSelection]="true">
  </ag-grid-angular>
</div>

