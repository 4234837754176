import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {CubeService, MessageType} from '../../cube/cube.service';
import {UserService} from '../../user/user.service';
import {Observable, of} from 'rxjs';
import {API} from '../api.const';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportFileService {

  constructor(private http: HttpClient,
              private logger: NGXLogger,
              public cubeService: CubeService,
              public userService: UserService) { }

  /**
   * DOWNLOAD File
   * @param url - params to filter by url
   */

  public downloadFile(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  public createObjectUrl(body: any, filename: string): void {
    if (body) {
      // Create a URL for the blob
      const urlObj = URL.createObjectURL(body);
      // Create an anchor element to "point" to it
      const anchor = document.createElement('a');
      anchor.href = urlObj;
      // Get the suggested filename for the file from the response headers
      anchor.download = filename || 'report.pdf';
      // Simulate a click on our anchor element
      anchor.click();
      // Discard the object data
      URL.revokeObjectURL(urlObj);
    }
  }

  /**
   * GET Files
   * @param resource - params to filter by resource
   * @param id - params to filter by id
   * @param url - params to filter by url
   */
  public getOfflineFiles(resource: string, id?: string, url?: string): Observable<any> {
    console.log(encodeURIComponent(url));
    return this.http.get<any>(`${API.FILE_REPORT.offlineFilesManager(resource, id, url)}`).pipe(
      tap((_) => this.logger.debug('fetched getOfflineFiles')),
      catchError(this.handleError<any>('getOfflineFiles'))
    );
  }


  /**
   * POST Files (Auditor Report)
   */
  public postAuditorFiles(body: any): Observable<any> {
    return this.http.post<any>(`${API.FILE_REPORT.offlineFilesManager()}`, body).pipe(
      tap((_) => this.logger.debug('fetched postAuditorFiles')),
      catchError(this.handleError<any>('postAuditorFiles'))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      this.cubeService.showMessage(MessageType.Error, error?.error?.error ? error?.error?.error : 'Failed');
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
