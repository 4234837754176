import {LOCALE_ID, NgModule} from '@angular/core';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { RemoveUnderscorePipe } from './remove-underscore/remove-underscore.pipe';
import { SelectionKeeperPipe } from './selection-keeper/selection-keeper.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { CoordinatePipe } from './coordinate/coordinate.pipe';
import { ExceededPipe } from './exceeded/exceeded.pipe';
import { MathRoundPipe } from './math-round/math-round.pipe';
import { TruncateUrlPipe } from './truncate-url/truncate-url.pipe';
import {TranslateModule} from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
registerLocaleData(it)

@NgModule({
  imports: [],
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterPipe,
    RemoveUnderscorePipe,
    SelectionKeeperPipe,
    CoordinatePipe,
    ExceededPipe,
    MathRoundPipe,
    TruncateUrlPipe
  ],
  exports: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterPipe,
    RemoveUnderscorePipe,
    SelectionKeeperPipe,
    CoordinatePipe,
    ExceededPipe,
    MathRoundPipe,
    TruncateUrlPipe,
    TranslateModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it'}
  ]
})
export class PipesModule {}
