export const MODULES = {
  homepage: {
    roles: ['AuditReports'],
    route: {
      path: 'homepage',
      loadChildren: () => import('../app/pages/homepage/homepage.module').then((m) => m.HomepageModule),
      data: { module: 'homepage' }
    },
    menu: {
      name: 'Homepage',
      mat_icon: 'home',
      open: true,
      link: 'homepage',
    }
  },
  'carbon-credit': {
    roles: ['CarbonCreditsIngestion'],
    route: {
      path: 'carbon-credit',
      loadChildren: () => import('../app/pages/carbon-credit/carbon-credit.module').then(m => m.CarbonCreditModule),
      data: { module: 'carbon-credit' }
    },
    menu: {
      name: 'Carbon Credits',
      mat_icon: 'eco',
      open: false,
      link: 'carbon-credit',
    }
  },
  'carbon-credit-validation': {
    roles: ['CarbonCreditsValidation'],
    route: {
      path: 'carbon-credit-validation',
      loadChildren: () => import('../app/pages/carbon-credit-validation/carbon-credit-validation.module').then(m => m.CarbonCreditValidationModule),
      data: { module: 'carbon-credit-validation' }
    },
    menu: {
      name: 'Carbon Credits Validation',
      mat_icon: 'pan_tool',
      open: false,
      link: 'carbon-credit-validation',
    }
  },
  'carbon-function': {
    roles: ['CarbonFootprintCalculationIngestion'],
    route: {
      path: 'carbon-function',
      loadChildren: () => import('../app/pages/carbon-function/carbon-function.module').then(m => m.CarbonFunctionModule),
      data: { module: 'carbon-function' }
    },
    menu: {
      name: 'Carbon Function',
      mat_icon: 'functions',
      open: false,
      link: 'carbon-function',
    }
  },
  'carbon-function-validation': {
    roles: ['CarbonFootprintCalculationValidation'],
    route: {
      path: 'carbon-function-validation',
      loadChildren: () => import('../app/pages/carbon-function-validation/carbon-function-validation.module').then(m => m.CarbonFunctionValidationModule),
      data: { module: 'carbon-function-validation' }
    },
    menu: {
      name: 'Carbon Function Validation',
      mat_icon: 'engineering',
      open: false,
      link: 'carbon-function-validation',
    }
  },
  'carbon-production-management': {
    roles: ['CarbonProductionManagement'],
    route: {
      path: 'carbon-production-management',
      loadChildren: () => import('../app/pages/carbon-production-management/carbon-production-management.module').then(m => m.CarbonProductionManagementModule),
      data: { module: 'carbon-production-management' }
    },
    menu: {
      name: 'Carbon Production',
      mat_icon: 'factory',
      open: false,
      link: 'carbon-production-management',
    }
  },
  'production-ddt-dashboard': {
    roles: ['PowerBiAnalytics_for_ProductionAndDDT'],
    route: {
      path: 'production-ddt-dashboard',
      loadChildren: () => import('../app/pages/production-ddt-analytics-dashboard/production-ddt-analytics-dashboard.module').then(m => m.ProductionDdtAnalyticsDashboardModule),
      data: { module: 'production-ddt-dashboard' }
    },
    menu: {
      name: 'Production & DDT Analytics',
      mat_icon: 'analytics',
      open: false,
      link: 'production-ddt-dashboard',
    }
  },
  'customer-management': {
    roles: ['CustomerManagement'],
    route: {
      path: 'customer-management',
      loadChildren: () => import('../app/features/user-management/user-management.module').then(module => module.UserManagementModule),
      data: { module: 'customer-management' }
    },
    menu: {
      name: 'Customer Management',
      mat_icon: 'people',
      open: false,
      link: 'customer-management'
    }
  }
};
