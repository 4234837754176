import {
  Directive,
  ElementRef,
  OnInit
} from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';
import { formatNumber } from '@angular/common';

@Directive({
  selector: '[cubeFormatNumber]',
  providers: [NgModel]
})

export class FormatNumberDirective implements OnInit {

  constructor(private control: NgControl, private element: ElementRef) { }

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value) => {
      // const t = '123.456.789,32';
      this.formatValue(value);
    });
  }

  private formatValue(value: string | null): void {
    // TODO: provare a fare la regex togliendo il punto.
    let int = value.split(',')[0];
    int = int.split('.').join('');
    const parsedValue = `${int},${value.split(',')[1]}`;
    const regex = /^-?[\d\.]*(\,\d+)?$/;
    if (!regex.test(value)) {
      this.element.nativeElement.value = '';
      return;
    }

    if (this.isLastCharacterDecimalSeparator(value)) {
      this.element.nativeElement.value = value;
      return;
    }
    // Conclude the decimal and thousand separators from locale
    const [thousandSeparator, decimalMarker] = ['.', ','];

    // Here value should always come with . as decimal marker thus any other behavior is bug
    const [integer, decimal] = value.split(',');

    // Group every three elements, and add thousandSeparator after them
    let formattedValue = '';
    formattedValue = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    // Add decimals and decimalMarker if any
    // this.element.nativeElement.value = formattedValue + decimalMarker + (decimal ? decimal : '');
    this.element.nativeElement.value = decimal ? formattedValue.concat(decimalMarker, decimal) : formattedValue;
  }

  isLastCharacterDecimalSeparator(value: any): boolean {
    return isNaN(value[value.length - 1]);
  }

}

