import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable} from 'rxjs';
import { ThemeService } from './services/theme/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import {STORAGE_KEYS} from './services/storage/storage.service';
import {delay, filter, takeUntil} from 'rxjs/operators';
import {CubeService} from './services';
import { Store } from '@ngrx/store';
import { AppState } from './store/states/app.state';
import { selectLoading, selectNotifications } from './store/selectors/system.selectors';
import { UnsubscriptionHandler } from './abstractions/unsubscription-handler.class';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SystemNotification } from './components/core/notification-snackbar/models/system-notification.class';
import { NotificationSnackbarComponent } from './components/core/notification-snackbar/notification-snackbar.component';

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UnsubscriptionHandler implements OnInit {
  isDarkTheme: boolean;
  loading$: Observable<boolean>;

  constructor(
    translate: TranslateService,
    private overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    private cubeService: CubeService,
    private readonly store: Store<AppState>,
    private readonly snackbar: MatSnackBar
  ) {
    super();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // a cosa serve?
    // this.title.setTitle(environment.SOLUTION === Solutions.FLEET40 ? 'RINA FLEET' : 'CUBE - OPTIMUM');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    localStorage.setItem(STORAGE_KEYS.localTimeFormat, translate.getBrowserLang());
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'en');

    this.loading$ = this.store.select(selectLoading).pipe(delay(500));
  }

  getRouteAnimation(outlet): void {
    return outlet.activatedRouteData.animation;
  }

  ngOnInit(): void {
      this.themeService.isDarkTheme.pipe(takeUntil(this.destroy$)).subscribe((isDark) => {
        this.isDarkTheme = isDark;
        this.overlayContainer.getContainerElement().classList.remove(isDark ? 'light-theme' : 'dark-theme');
        this.overlayContainer.getContainerElement().classList.add(!isDark ? 'light-theme' : 'dark-theme');
      })

    fromEvent(window, 'storage')
      .pipe(filter((ev: StorageEvent) => ev.key === STORAGE_KEYS.currentTenant), takeUntil(this.destroy$))
      .subscribe((storageEvent: StorageEvent) => {
        this.cubeService.cleanCache();
        window.location.reload();
      });

    this.store.select(selectNotifications).pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (notification: SystemNotification | undefined): void => {
        if (notification) {
          const { duration, horizontalPosition, verticalPosition, panelClass, data } = notification.options;
          const snackBarConfig: MatSnackBarConfig = {
            duration,
            horizontalPosition,
            verticalPosition,
            panelClass,
            data,
          };

          this.snackbar.openFromComponent(NotificationSnackbarComponent, snackBarConfig);
        }
      },
    });
  }
}
