import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly _storage = localStorage;

  constructor() {}

  addToStorage(key: string, value: any): void {
    this._storage.setItem(key, JSON.stringify(value));
  }

  getFromStorage(key: string): any {
    return JSON.parse(this._storage.getItem(key));
  }

  deleteFromStorage(key: string): any {
    this._storage.removeItem(key);
  }
}

export const STORAGE_KEYS = {
  token: 'token',
  version: 'version',
  sub: 'sub',
  localTimeFormat: 'local_time_format',
  selectedShips: 'selected-ships',
  selectedShip: 'selected-ship',
  excludedMap: 'excluded-map',
  darkTheme: 'is-dark-theme',
  analyticsDashboard: 'analytics-dashboard',
  activeRole: 'active-role',
  userFilters: 'user-filters',
  shiplistLayout: 'shiplist-layout',
  mapBaseLayer: 'map-base-layer',
  syncAssetPicker: 'sync-asset-picker',
  analyticsMode: 'analytics-mode',
  currentTenant: 'current-tenant',
  kpiActivation: 'kpiActivation'
};
