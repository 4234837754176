import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {API} from '../api.const';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  getAllTenants(username: string, solution: string): Observable<any> {
    return this.http.get(`${API.ADMIN_TOOL.tenants(username, solution)}`).pipe(
      map((res: any[]) => res?.map(r => r.name)),
      tap(_ => this.logger.debug('getAllTenants')),
      catchError(this.handleError<any>())
    );
  }

  getProfile(username: string): Observable<any> {
    return this.http.get(`${API.ADMIN_TOOL.userProfile(username)}`).pipe(
      map(res => res),
      tap(_ => this.logger.debug('getProfile')),
      catchError(this.handleError<any>())
    );
  }

  getRoleFeatures(role: string): Observable<any> {
    return this.http.get(`${API.ADMIN_TOOL.roleFeatures(role)}`).pipe(
      map(res => res),
      tap(_ => this.logger.debug('getRoleFeatures')),
      catchError(this.handleError<any>())
    );
  }

  getRoleMeasures(role: string): Observable<any> {
    return this.http.get(`${API.ADMIN_TOOL.roleMeasures(role)}`).pipe(
      map(res => res),
      tap(_ => this.logger.debug('getRolesMeasures')),
      catchError(this.handleError<any>())
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
