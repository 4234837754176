import { globalEnvironment } from './global';
import {MODULES} from './module.config';
import {BASE_URL} from './commons/base-url';
import {ARVEDI_COMMON_ENV} from "./commons/dias-arvedi";

export const environment = {
  ...globalEnvironment,
  ...BASE_URL,
  ...ARVEDI_COMMON_ENV,
  auth: {
    ...globalEnvironment.auth,
    bearerExcludedUrls: [...globalEnvironment.auth.bearerExcludedUrls, '^https://diasarvedistorage.blob.core.windows.net/'], // url of pdf reports
  }
};
