<div class="sidebar">

    <div fxLayout="column" fxLayoutAlign="center center"
        style="padding: 30px 0; background: url('./assets/rina/RINA-light.png');">
        <div style="width: 100%; height: 22%; position: absolute;background: #17161642;z-index: 1"></div>
        <div class="mat-display-2" style="margin: 0; color: white;z-index: 2">{{today | date:'shortTime'}}</div>
        <div class="mat-subheading-2" style="margin: 0;color: white;z-index: 2">{{today | date:'EEEE'}},
            {{today | date:'MMMM'}} {{today | date:'dd'}}
        </div>
    </div>

    <mat-divider></mat-divider>

    <h3 class="mat-subheading-2" style="margin: 10px 14px 0;">Upcoming Events</h3>
    <mat-nav-list>
        <mat-list-item>
            <mat-slide-toggle [checked]="isDarkTheme | async" labelPosition="before" (change)="toggleDarkTheme($event.checked)">Dark theme</mat-slide-toggle>
        </mat-list-item>
    </mat-nav-list>
</div>
