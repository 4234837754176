export interface AuditUpdateStatus {
  id: string;
  audit_status: Status;
  audit_revision_notes?: string;
}

export enum Status {
  started = 'started',
  revision = 'revision',
  inserted = 'inserted',
  submitted = 'submitted',
  approved = 'approved',
  not_approved = 'not_approved'
}

export enum FunctionStatus {
  active = 'active',
  expired = 'expired',
  not_active = 'not_active'
}

export enum ProductionStatus {
  inserted = 'inserted',
  submitted = 'submitted',
  canceled = 'canceled',
  closed = 'closed'
}


