import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'cube-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  today: number = Date.now();

  isDarkTheme: Observable<boolean>;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.isDarkTheme = this.themeService.isDarkTheme;
  }

  toggleDarkTheme(checked: boolean) {
    this.themeService.setDarkTheme(checked);
  }
}
